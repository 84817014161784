<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div @click="createItem" class="add-btn">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>
    <div id="productList">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="filteredProductList" :search="search" :footer-props="footerProps"
              :items-per-page="100" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title">Професионалы
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                    <input type="search" class="search-component__input mr-10" v-model="search"
                      append-icon="mdi-magnify" placeholder="Поиск" single-line hide-details />
                    <svg width="17" height="17" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        {{
          isEditing === true
            ? "Редактирование категории"
            : "Новый Професионал"
        }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">ФИО</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 30px" class="col-description" v-model="playerName"
                                ref="playerNameTextarea" :placeholder="isplayerNameFocused
          ? ''
          : textareaPlaceholder
          " @focus="isplayerNameFocused = true" @blur="isplayerNameFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pb-0 pt-3 pb-3">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Номер в списке</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <!-- <span class="red-dot">*</span> -->
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <input class="col-description" v-model="sortOrderPlayer" :placeholder="issortOrderPlayerFocused
          ? ''
          : textareaPlaceholder
          " @focus="issortOrderPlayerFocused = true" @blur="issortOrderPlayerFocused = false" @input="handleInput" />
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pb-0 pt-3">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">ФИО EN</p>
                            </v-col>
                            <!-- <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col> -->
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 30px" class="col-description" v-model="playerNameEN"
                                ref="playerNameENTextarea" :placeholder="isplayerNameENFocused
          ? ''
          : textareaPlaceholder
          " @focus="isplayerNameENFocused = true" @blur="isplayerNameENFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Дата Рождения</p>
                            </v-col>
                            <!-- <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col> -->
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0 pb-4">
                              <input type="date" style="height: 15px; width: 260px" class="col-description"
                                v-model="playerDateBirth" ref="orderNumTextarea" :placeholder="isdateBirthFocused ? '' : textareaPlaceholder
          " @focus="isdateBirthFocused = true" @blur="isdateBirthFocused = false">
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>

                      <div :class="isEditing === true
          ? 'btn-container'
          : 'btn-container-1 px-6'
          ">
                        <div v-if="isEditing" :class="currentState === 'Active'
          ? 'save-btn-second'
          : 'delete-btn'
          " text @click="setCategoryState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div :class="isEditing === true ? 'save-btn' : 'save-btn ml-7'
          " text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="717px">
                    <v-card>
                      <v-card-title class="warning-title">{{
          currentState === "Active"
            ? "Вы точно хотите активировать данного профессионала?"
            : "Вы точно хотите деактивировать данного профессионала?"
        }}
                        <v-btn icon @click="closeDelete">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-actions class="warning-margin">
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div :class="currentState === 'Active'
          ? 'save-btn-paswd'
          : 'del-btn'
          " text @click="setUserState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr style="cursor: pointer">
                  <td @click="entetPlayer(item)">{{ item.name }}</td>
                  <td @click="entetPlayer(item)">{{ item.sortOrder }}</td>
                  <td @click="entetPlayer(item)">
                    {{ item.birthYear | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="entetPlayer(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="entetPlayer(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="entetPlayer(item)">
                    {{ item.createdBy.fullName }}
                  </td>
                  <td @click="entetPlayer(item)">
                    {{ getRoleTranslation(item.stateCode) }}
                  </td>
                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index, $event)">mdi-dots-vertical</v-icon>
                    <div class="ActionMenu" v-if="showActionMenu[index]" :ref="'ActionMenu' + index">
                      <div @click="entetPlayer(item)" class="ActionMenu-item-1">
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        {{
          item.stateCode === "Active"
            ? "Деактивировать"
            : "Активировать"
        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <div v-if="showNoDataMessage">Нет подходящих записей.</div>
                <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
              </template>
              <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во игроков на странице:
                    <v-select class="custom-select" v-model="itemsPerPage" :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage" hide-details></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button type="button" :disabled="currentPage === 1" class="
                        v-btn v-btn--icon v-btn--round v-btn--text
                        theme--light
                        v-size--default
                      " :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                      @click="goToPreviousPage">
                      <span class="v-btn__content">
                        <i aria-hidden="true" class="
                            v-icon
                            notranslate
                            mdi mdi-chevron-left
                            theme--light
                          "></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button type="button" :disabled="currentPage === totalPages" class="
                        v-btn v-btn--icon v-btn--round v-btn--text
                        theme--light
                        v-size--default
                      " :class="{ 'v-btn--disabled': currentPage === totalPages }" aria-label="Следующая страница"
                      @click="goToNextPage">
                      <span class="v-btn__content">
                        <i aria-hidden="true" class="
                            v-icon
                            notranslate
                            mdi mdi-chevron-right
                            theme--light
                          "></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
  name: "Players",
  data: () => ({
    showActionMenu: [],
    dialogDelete: false,
    dialogCreate: false,
    isEditing: false,
    search: "",
    snackbar: false,
    message: "",
    isPlaceholderHidden: false,
    isplayerNameFocused: false,
    isplayerNameENFocused: false,
    isdateBirthFocused: false,
    playerName: "",
    playerNameEN: "",
    playerDateBirth: "",
    issortOrderPlayerFocused: false,
    sortOrderPlayer: "",
    // productListSearch: [],
    statusTranslations: {
      Active: "Активный",
      Inactive: "Неактивный",
    },
    test: ["Активный", "Неактивный"],
    newPlayer: {},
    selectedType: null,
    // applicationTypes: [
    //   { label: "CustomerApp", value: 1 },
    //   { label: "RestaurantApp", value: 2 },
    //   { label: "WebApp", value: 3 },
    // ],
    headers: [
      {
        text: "ФИО",
        align: "start",
        value: "name",
      },
      { text: "Номер в списке", value: "sortOrder" },
      { text: "Дата рождения", value: "birthYear" },
      { text: "Дата создания", value: "createdOn" },
      { text: "Дата изменения", value: "modifiedOn" },
      { text: "Пользователь", value: "" },
      { text: "Статус", value: "stateCode" },
      { text: "Действия", align: "end" },
      // { text: "Действия",  align: "end", value: "actions", sortable: false },
    ],
    productList: [],
    profiles: [],
    error: null,
    currentState: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    showNoDataMessage: ""
  }),

  created() {
    this.initialize();
    // this.initializeSearch();
    // this.profileInfo();
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
    filteredProductList() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      // if (this.search) {
      //   return this.productListSearch.items.filter((item) => {
      // // Добавьте свою логику поиска здесь
      // return (
      //   (item.name && item.name.toLowerCase().includes(this.search)) ||  // Поиск по имени
      //   (item.description && item.description.includes(this.search)) ||  // Поиск по описанию
      //   (item.origin && item.origin.includes(this.search))
      //   );
      // });
      if (this.selectedType) {
        return this.productList.items
          .filter
          // (item) => item.applicationType === this.selectedType
          ();
      } else {
        return this.productList.items;
      }
    },

    footerProps() {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, 100], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
  },
  methods: {
    handleInput() {
      // Оставить только цифры
      this.sortOrderPlayer = this.sortOrderPlayer.replace(/\D/g, "");
    },
    toggleActionMenu(index, event) {
      event.stopPropagation(); // Остановить распространение события, чтобы оно не закрыло меню
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      let clickedOutside = true;
      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const menuRef = this.$refs["ActionMenu" + i];
          if (menuRef && menuRef.contains(event.target)) {
            clickedOutside = false;
            break;
          }
        }
      }
      if (clickedOutside) {
        for (let i = 0; i < this.showActionMenu.length; i++) {
          this.$set(this.showActionMenu, i, false);
        }
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    getRoleTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    getTranslatedStatus(stateCode) {
      if (stateCode === "Active") {
        return "Активный";
      } else if (stateCode === "Inactive") {
        return "Неактивный";
      }
      // Другие возможные значения статуса
      return stateCode; // Вернуть исходное значение, если не найдено соответствие
    },
    handleButtonClick() {
      // Выполните метод для создания нового приложения
      this.createNewPlayer();

    },
    initialize() {
      this.$http
        .put(`/api/Player/PagedList`, {
          //     params: {
          // pageNumber: this.currentPage,
          // pageSize: this.itemsPerPage,
          //   },
          filters: [
            {
              property: "Name",
              operator: "contains",
              value: this.search,
            },
          ],
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          sorting: {
            property: "CreatedOn",
            isDescending: true,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
          this.productList = response.data; // Получаем массив элементов
          // this.currentPage = response.data.pageNumber; // Текущая страница
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          if (this.totalItems === 0) {
            this.showNoDataMessage = true;
          } else {
            this.showNoDataMessage = false;
          }
          // this.hasNextPage = response.data.hasNextPage;
          // this.hasPreviousPage = response.data.hasPreviousPage;
          // // Возможно, также нужно обработать значения hasNextPage и hasPreviousPage в вашем компоненте.
        })
        .catch((error) => (this.error = error));
    },
    // initializeSearch() {
    //   this.$http
    //     .put(`/api/Product/PagedList`, {
    //       //     params: {
    //       // pageNumber: this.currentPage,
    //       // pageSize: this.itemsPerPage,
    //       //   },
    //       pageNumber: this.currentPage,
    //       pageSize: 1000,
    //       sorting: {
    //         property: "CreatedOn",
    //         isDescending: true
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
    //       this.productListSearch = response.data; // Получаем массив элементов
    //       // this.currentPage = response.data.pageNumber; // Текущая страница
    //       // this.totalPages = response.data.totalPages; // Общее количество страниц
    //       // this.totalItems = response.data.totalCount; // Общее количество элементов
    //       console.log(this.totalItems);
    //       // this.hasNextPage = response.data.hasNextPage;
    //       // this.hasPreviousPage = response.data.hasPreviousPage;
    //       // // Возможно, также нужно обработать значения hasNextPage и hasPreviousPage в вашем компоненте.
    //     })
    //     .catch((error) => (this.error = error));
    // },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },
    entetPlayer(item) {
      this.$router.push({ path: `/player-body/${item.id}` });
    },
    //   profileInfo() {
    //     this.$http
    //       .get("/api/Contact/GetMyProfile")
    //       .then((response) => {
    //         this.profiles = response.data;
    //       })
    //       .catch((error) => (this.error = error));
    //   },

    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredProductList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/api/Application/${this.editedItem.id}`)
        .then(() => {
          this.applications.items.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((error) => {
          alert(error);
        });
    },
    setUserState() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .put(`/api/Player/SetState/${this.currentCategory}`, null, {
          params: {
            stateCode: this.currentState,
          },
        })
        .then((response) => {
          this.curUser = response.data;
          this.initialize();
          this.closeDelete();
          this.snackbar = true;
          this.message = "Действие прошло успешно";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.message = "Ошибка выполнения";
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.playerName = "";
      this.playerNameEN = "";
      this.playerDateBirth = "";
      this.isEditing = false;
    },
    closeDialog() {
      this.dialogCreate = false;
      this.playerName = "";
      this.playerNameEN = "";
      this.playerDateBirth = "";
      this.sortOrderPlayer = "";
      this.isEditing = false;
    },

    closeCreate() {
      this.dialogCreate = false;
      this.playerName = "";
      this.playerNameEN = "";
      this.playerDateBirth = "";
      this.sortOrderPlayer = "";
      this.isEditing = false;
    },
    createNewPlayer() {
      if (!this.playerName || this.playerName.trim() === '') {
        this.snackbar = true;
        this.message = "ФИО обязательно для заполнения";
        return;
      }
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //     this.selectedOption
      //   );
      (this.newPlayer.name = this.playerName);
      // (this.newEmp.mobilePhone = this.phone),
      (this.newPlayer.localizations = [
        {
          locale: "en",
          name: this.playerNameEN,
        },
      ]),
      this.newPlayer.sortOrder = this.sortOrderPlayer || null;
      if (!this.playerDateBirth) {
        (this.newPlayer.birthYear = null);
      }
      else {
        (this.newPlayer.birthYear = new Date(this.playerDateBirth).toISOString() || "");
      }
      this.$http
        .post("/api/Player", this.newPlayer)
        .then(() => {
          this.initialize();
          this.dialogCreate = false;
          this.newPlayer = {
            name: "",
            birthYear: null,
            localizations: [
                {
                  locale: "",
                  name: "",
                },
              ],
          };

          this.snackbar = true;
          this.message = "Профессионал успешно добавлен";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка добавление профессионала";
        });
    },



    editItem(item) {
      this.$router.push({ path: `/admin/application/${item.id}` });
    },
  },
  watch: {
    search: debounce(function () {
      // Выполняем запрос с задержкой после изменения переменной поиска
      this.initialize();
    }, 500),
    playerName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("playerNameTextarea");
      });
    },
    playerNameEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("playerNameENTextarea");
      });
    },
  },
};
</script>
<style scoped>
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
}

.btn-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}

.warning-margin {
  margin-top: 16px;
  margin-bottom: 10px;
}

.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.save-btn-paswd {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.ActionMenu {
  position: absolute;
  /* bottom: -110px;
    left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}

.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(242, 242, 246, 0.5);
  /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-top-right-radius: 10px;
  /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}

.ActionMenu-item-2 {
  border-bottom-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px;
  /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.custom-select .v-list {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
}

.custom-select .v-list-item {
  width: 100%;
  text-align: center !important;
}

.v-btn--disabled {
  color: #ccc;
  /* Цвет текста */
  cursor: not-allowed;
  /* Курсор "не разрешено" */
}

::v-deep .v-toolbar__content {
  align-items: center !important;
}

/* Применяем стили для выпадающего списка */
.custom-select .v-select__slot {
  padding: 7px;
  /* Уменьшаем вертикальный отступ */
  min-height: 30px;
  /* Минимальная высота */
  text-align: center;
}

.custom-select .v-select__slot>.v-list-item__content {
  text-align: center;
}

/* .v-list-item__content {
      flex-direction: column;
    } */
/* Применяем стили для элементов списка */
.custom-select .v-list-item {
  height: 24px;
  /* Высота элемента */
  min-height: 24px;
  /* Минимальная высота элемента */
  line-height: 24px;
  width: 100%;
  /* Высота строки */
}

/* Применяем стили для текста элемента списка */
.custom-select .v-list-item__title {
  font-size: 12px;
  text-align: center;
  padding-left: 10px;
}

/* Применяем стили для выпадающего списка при наведении */
.custom-select .v-list-item:hover {
  background-color: #f0f0f0;
  /* Цвет фона при наведении */
}

.custom-select {
  position: relative;
  width: 294px;
  cursor: pointer;
  margin-left: 19px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 48%;
  right: 30px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.col-description,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}

.role-margin {
  margin-right: 37px;
}

.col-content-2 {
  display: flex;
  align-items: center;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  min-height: 20px;
  /* Минимальная высота для textarea */
  width: 100%;
  margin-left: 18px;
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  /* margin-left: 37px; */
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.search-component {
  /* max-width: 606px; */
  width: 30vw;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 30%;
  right: 55px;
}

#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff) !important;
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}

.staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff) !important;
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
  text-decoration: none;
}

::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid #848484;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
